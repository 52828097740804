import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { Subscription } from 'rxjs';
import { ClinicDetail } from 'src/app/dashboard/shared/clinic.models';

import { IContactForm } from '../../../schedule-wise/schedule-staff/models/contact.model';
import { ProviderService } from '../../../schedule-wise/services/provider.service';
import { ClinicService } from '../../services/clinic.service';

@Component({
  standalone: true,
  selector: 'contact-us-modal',
  templateUrl: './contact-us-modal.component.html',
  styleUrls: ['./contact-us-modal.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputMaskModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
  ],
})
export class ContactUsModalComponent implements OnInit, OnDestroy {
  public contactForm: UntypedFormGroup;
  public errorMessageDisplayed: string = 'This field is required';
  public selectedClinic: string = '---';

  private subcription: Subscription = new Subscription();

  public constructor(
    public dynamicDialogRef: DynamicDialogRef,
    private fb: UntypedFormBuilder,
    private providerService: ProviderService,
    private clinicService: ClinicService,
    private ms: MessageService,
  ) {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      message: ['', [Validators.required, Validators.maxLength(500)]],
    });
  }

  public ngOnInit(): void {
    this.subcription.add(
      this.clinicService.getClinic().subscribe((data: ClinicDetail) => {
        this.selectedClinic = `${data.CenterName} (${data.CenterCode})`;
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subcription.unsubscribe();
  }

  public onSendIt(): void {
    const contactForm: IContactForm = this.contactForm.getRawValue() as IContactForm;
    contactForm.selectedClinic = this.selectedClinic;

    this.providerService.sendContactUsForm(contactForm).subscribe({
      next: (res: IContactForm) => {
        if (res) {
          this.dynamicDialogRef.close();
          this.ms.add({
            severity: 'success',
            detail: 'The form has been submitted successfully!',
          });
        }
      },
      error: (err) => {
        if (err && err.error) {
          this.ms.add({ severity: 'error', detail: `Error submitting the form: ${err?.error?.error}` });
        }
      },
    });
  }
}
