import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { firstValueFrom } from 'rxjs';
import { routeSomewhere } from 'src/app/shared/helpers/routeSomewhere';
import { ApiService } from 'src/app/shared/services/api.service';
import { SessionService, UserLogin } from 'src/app/shared/services/session.service';
import { environment } from 'src/environments/environment';

import { setRedirectUrlBaseOnRole } from '../helpers/getHome';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public username = new UntypedFormControl('');
  public password = new UntypedFormControl('');
  public loginError = false;
  public errorMessage = '';

  constructor(
    private sessionService: SessionService,
    private cookieService: CookieService,
    private router: Router,
    private route: ActivatedRoute,
    private ApiService: ApiService,
  ) {}
  ngOnInit(): void {
    this.tryLogingIn();
  }

  private async tryLogingIn() {
    try {
      // if this succeeds you are logged in
      const res = await firstValueFrom(
        this.ApiService.makeAuthRequest<UserLogin>('post', 'session-validation', {
          loadingMessage: 'Authenticating User',
          ignoreErrors: true,
        }),
      );

      const returnUrl = (await firstValueFrom(this.route.queryParamMap)).get('returnUrl');
      // check if the person is logged in but got here by accident
      // then return them to the place they were with the returnUrl query param
      if (returnUrl) {
        routeSomewhere(returnUrl, this.router);
      } else {
        const home = setRedirectUrlBaseOnRole(res.userCenterRoleCode);
        this.router.navigateByUrl(home);
      }
      return true;
    } catch (error) {
      return false;
    }
  }

  async submitLogin() {
    try {
      const user = await this.sessionService.loginNonSSO(this.username.value, this.password.value);

      this.errorMessage = '';
      this.loginError = false;
      if (environment.local) {
        this.cookieService.set('SW_ENV', '1');
      }

      if (user.tempPassword) {
        return this.router.navigate(['/', 'login', 'passwords']);
      }

      const returnUrl = (await firstValueFrom(this.route.queryParamMap)).get('returnUrl');
      if (returnUrl) {
        routeSomewhere(returnUrl, this.router);
      } else {
        this.router.navigate([setRedirectUrlBaseOnRole()]);
      }
    } catch (error) {
      if (error.status === 401) {
        this.errorMessage = 'Username or password is incorrect, please try again.';
        this.loginError = true;
      } else {
        this.ApiService.handleError(error, 'Login Error, please try again.');
        this.errorMessage = 'Login Error, please try again.';
        this.loginError = true;
      }
    }
  }
}
