import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';
import { DayLettersUnique, FrequencyIntervalDays } from 'src/app/shared/enums/frequency-intervals';

import { IPatientsModalResponse } from '../../models/patient-modal';
import { DEFAULT_ACCUITIES, IPatientTreatmentEvent } from '../../models/schedule-patient.models';
import { PatientScheduleService } from '../../services/patient-schedule.service';
import { PatientsModalService } from '../patients-modal/patients-modal.service';

@Component({
  standalone: false,
  selector: 'existing-patient-modal',
  templateUrl: './existing-patient-modal.component.html',
  styleUrls: ['./existing-patient-modal.component.scss'],
  providers: [DialogService],
})
export class ExistingPatientModalComponent implements OnInit {
  public patients: IPatientsModalResponse[];
  public treatment: IPatientTreatmentEvent;
  public treatments: IPatientTreatmentEvent[];
  public legacyIdCenter: number;
  public filterFields = ['PatientFirstName', 'PatientLastName', 'MedicalRecordNumber'];
  private scheduleType: number;

  constructor(
    private config: DynamicDialogConfig,
    private confirmationService: ConfirmationService,
    private ref: DynamicDialogRef,
    private messageService: MessageService,
    private patientsModalService: PatientsModalService,
    private patientScheduleService: PatientScheduleService,
  ) {}

  ngOnInit(): void {
    this.legacyIdCenter = this.config.data.legacyIdCenter;
    this.treatment = this.config.data.treatment;
    this.scheduleType = this.config.data.scheduleType;
    this.patientsModalService.getPatients(this.legacyIdCenter, this.scheduleType).subscribe((res) => {
      this.patients = res;
    });
  }

  public addPatient(patient: IPatientsModalResponse): void {
    if (this.checkScheduleConflicts(patient)) {
      this.removePatient(patient);
    } else {
      this.addPatientToTreatment(patient, this.treatment);
    }
  }

  public checkScheduleConflicts(pt: IPatientsModalResponse): boolean {
    // Check if the patient already has a treatment on any of these days
    let conflict = false;
    if (pt.WeekDays) {
      const txDays = FrequencyIntervalDays[this.treatment.FrequencyInterval];
      const ptDays = pt.WeekDays.split(',');
      if (ptDays.length) {
        txDays.forEach((d) => {
          if (ptDays.indexOf(DayLettersUnique[d - 1]) >= 0) {
            conflict = true;
          }
        });
      }
    }
    return conflict;
  }

  private removePatient(patient: IPatientsModalResponse): void {
    this.confirmationService.confirm({
      message: `You need to remove this patient's existing schedule. Would you like to do that now?`,
      header: 'Confirm Removal',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Remove',
      rejectLabel: 'Cancel',
      accept: () => {
        // Close old treatments
        this.patientScheduleService.getScheduleForPatient(patient.IdPatient, this.scheduleType).subscribe({
          next: async (patientTx) => {
            // find the treatments for this frequencyInterval
            let treatments = patientTx.filter((tx) =>
              FrequencyIntervalDays[this.treatment.FrequencyInterval].includes(tx.IdWeekDay + 1),
            );

            // This is not a shared chair so we really only need the first treatment's info
            if (treatments.length === 3) {
              treatments = [treatments[0]];
              // delete the IdWeekDay so that the close function will remove the whole treatment
              delete treatments[0].IdWeekDay;
            }

            // if this is a shared chair close each treatment event
            // but also because we only took the first treatment above this will handle non-shared chairs
            for (const tx of treatments) {
              const resClose = await firstValueFrom(
                this.patientScheduleService.closeTreatmentEvent(
                  tx.IdEventSchedule,
                  tx.IdChair,
                  tx.ScheduleShift,
                  tx.IdWeekDay,
                  tx.StartTime,
                  tx.DurationInMinutes,
                ),
              );
              if (resClose.statusCode !== 200) {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Patient Error',
                  detail: `There was an error removing this patient's previous treatments.`,
                });
                return;
              }
            }

            this.messageService.add({
              severity: 'success',
              summary: 'Remove Patient',
              detail: `The patient's previous treatments have been removed.`,
            });
            // Create new treatment
            this.closeTreatmentAndAddPatient(patient, this.treatment);
          },
        });
      },
    });
  }

  private addPatientToTreatment(patient: IPatientsModalResponse, tx: IPatientTreatmentEvent): void {
    this.confirmationService.confirm({
      message: `Do you want to add ${patient.PatientFirstName} ${patient.PatientLastName} to this chair?`,
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Add Patient',
      rejectLabel: 'Cancel',
      accept: () => {
        this.closeTreatmentAndAddPatient(patient, tx);
      },
    });
  }

  private closeTreatmentAndAddPatient(patient: IPatientsModalResponse, tx: IPatientTreatmentEvent): void {
    this.patientScheduleService
      .closeTreatmentEvent(tx.IdEventSchedule, tx.ChairId, tx.ShiftNumber)
      .subscribe((resClose) => {
        if (resClose?.statusCode === 200) {
          this.patientScheduleService
            .createTreatmentEvent(
              this.legacyIdCenter,
              tx.IdEventSchedule,
              tx.ChairId,
              tx.ShiftNumber,
              tx.StartTime || '00:00',
              patient.DurationInMinutes,
              patient.IdPatient,
              DEFAULT_ACCUITIES.AcuityOnPre,
              DEFAULT_ACCUITIES.AcuityOffPre,
              DEFAULT_ACCUITIES.AcuityOnPost,
              DEFAULT_ACCUITIES.AcuityOffPost,
            )
            .subscribe((resCreate) => {
              if (resCreate?.length && resCreate[0].SuccessCode) {
                this.messageService.add({
                  severity: 'success',
                  summary: 'Patient Added',
                  detail: 'Patient was added to this chair.',
                });
                this.close(true);
              } else {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Patient Error',
                  detail: 'There was an error adding this patient to the schedule.',
                });
              }
            });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Patient Error',
            detail: 'There was an error with this chair.',
          });
        }
      });
  }

  private close(refresh: boolean): void {
    this.ref.close(refresh);
  }
  onFilterInput(event: Event, table: any) {
    const input = (event.target as HTMLInputElement).value;
    table.filterGlobal(input, 'contains');
  }
}
