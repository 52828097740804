import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { routeSomewhere } from 'src/app/shared/helpers/routeSomewhere';
import { SessionService } from 'src/app/shared/services/session.service';

@Component({
  selector: 'app-login-redirect',
  templateUrl: './login-redirect.component.html',
  styleUrls: ['./login-redirect.component.scss'],
})
export class LoginRedirectComponent {
  public returning: boolean;

  constructor(private router: Router, private route: ActivatedRoute, private sessionService: SessionService) {
    this.checkReturn();
  }

  async checkReturn() {
    const returnUrl = (await firstValueFrom(this.route.queryParamMap)).get('returnUrl');
    // check if the person is logged in but got here by accident
    // then return them to the place they were with the returnUrl query param
    if (returnUrl) {
      this.returning = true;
      routeSomewhere(returnUrl, this.router);
    } else {
      this.router.navigate(['/']);
    }
  }
}
