export interface IPodConfig {
  id: number;
  legacyId: number;
  configId: number;
  sortOrder: number;
  name: string;
  chairs: IChairConfig[];
  zone: number;
  pairedPodId: number | undefined;
  prevPairedPodId: number | undefined;
  pairable: boolean;
  updateStatus: UpdateStatus;
  staffedDays: { [days: string]: boolean };
  staffed: boolean;
  occupied?: boolean;
  namingConflict: boolean;
  zoneConflict?: boolean;
  activeChairs: number;
  prevName?: string;
}

export interface IPairablePod {
  name: string;
  id: number;
  disabled: boolean;
  sortOrder: number;
}

export interface IChairConfig {
  id: number;
  legacyId: number;
  podId: number;
  name: string;
  sortOrder: number;
  type: number;
  status: number;
  statusReason: number;
  shift: number;
  updateStatus: UpdateStatus;
  occupiedDays: { [days: string]: boolean };
  occupied: boolean;
  namingConflict: boolean;
  zoneConflict: boolean;
  prevName?: string;
}

export interface IPodPairRes {
  clinicID: number;
  podID: number;
  podName: string;
  podTypeID: number;
  sortOrder: number;
  legacyIdPod: number;
  podGroupID: number;
  podGroupTypeID: number;
  index?: number;
}

export enum UpdateStatus {
  NoChange = 1,
  Change = 2,
  Add = 3,
  Remove = 4,
}

export const chairTypes = [
  { id: 1, name: 'Chair' },
  { id: 2, name: 'Bed' },
  { id: 3, name: 'Bariatric' },
  { id: 4, name: 'Buffer' },
  { id: 5, name: 'Isolation' },
];

export const zoneTypes = [
  { id: 1, name: 'Normal' },
  { id: 2, name: 'Buffer' },
  { id: 3, name: 'Isolation' },
];

export enum ChairType {
  Chair = 1,
  Bed = 2,
  Bariatric = 3,
  Buffer = 4,
  Isolation = 5,
}

export enum ZoneType {
  Normal = 1,
  Buffer = 2,
  Isolation = 3,
}
