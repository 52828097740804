import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { ApiService, IRequestBody } from 'src/app/shared/services/api.service';

import {
  IPatientInfoModalResponse,
  IPatientShiftEvent,
  IPatientsModalResponse,
  IPhysiciansListResponse,
  IScheduleEvent,
  ITransportationListResponse,
} from '../../models/patient-modal';

@Injectable({
  providedIn: 'root',
})
export class PatientsModalService {
  constructor(private apiService: ApiService) {}

  updatePatient(patientInfo: IPatientInfoModalResponse) {
    const startDate = DateTime.fromJSDate(patientInfo.patient.startDateJs);
    return this.apiService.makeRequest<void>('put', `patients/patient/`, {
      payload: {
        legacyIdCenter: patientInfo.patient.IdCenter,
        idPatient: patientInfo.patient.IdPatient,
        firstName: patientInfo.patient.PatientFirstName,
        lastName: patientInfo.patient.PatientLastName,
        middleName: patientInfo.patient.PatientMiddleName,
        medicalRecordNumber: patientInfo.patient.MedicalRecordNumber,
        scheduleConstraintNote: patientInfo.patient.ScheduleConstraintNote,
        patientNote: patientInfo.patient.PatientNote,
        physicianId: patientInfo.patient.Physician_IdProvider,
        transportationId: patientInfo.patient.IdTransportation,
        patientStatus: this.getPatientStatus(patientInfo),
        hepBStatusId: patientInfo.patient.HepBStatusId,
        idPatientMaster: patientInfo.patient.IdPatientMaster,
        startDate: startDate.isValid ? startDate.toFormat('yyyy-LL-dd') : undefined,
      },
    });
  }

  getPatientStatus(patientInfo: IPatientInfoModalResponse) {
    let output = '';

    if (patientInfo.patient.Anchor) output += `Anchor,`;
    if (patientInfo.patient.Assist) output += `Assist,`;
    if (patientInfo.patient.AVFistula) output += `AVFistula,`;
    if (patientInfo.patient.AVGraft) output += `AVGraft,`;
    if (patientInfo.patient.Catheter) output += `Catheter,`;
    if (patientInfo.patient.HoyerLift) output += `HoyerLift,`;
    if (patientInfo.patient.Integrated) output += `Integrated,`;
    if (patientInfo.patient.NewPatient) output += `NewPatient,`;
    if (patientInfo.patient.Transient) output += `Transient,`;
    if (patientInfo.patient.Wheelchair) output += `Wheelchair,`;

    // remove last comma
    output = output.replace(/,$/, '');

    return output;
  }

  updatePatientStatus(patientInfo: IPatientInfoModalResponse) {
    return this.apiService.makeRequest<void>('put', `patients/patient/status`, {
      payload: {
        idPatient: patientInfo.patient.IdPatient,
        anchor: !!patientInfo.patient.Anchor,
        assist: !!patientInfo.patient.Assist,
        avFistula: !!patientInfo.patient.AVFistula,
        avGraft: !!patientInfo.patient.AVGraft,
        catheter: !!patientInfo.patient.Catheter,
        hoyerLift: !!patientInfo.patient.HoyerLift,
        integrated: !!patientInfo.patient.Integrated,
        newPatient: !!patientInfo.patient.NewPatient,
        transient: !!patientInfo.patient.Transient,
        wheelchair: !!patientInfo.patient.Wheelchair,
      },
    });
  }

  updatePatientMemo(shift: IScheduleEvent) {
    return this.apiService.makeRequest<void>('put', `patients/patient/memo`, {
      payload: {
        idScheduleEvent: shift.IdScheduleEvent,
        ScheduleNote: shift.ScheduleNote,
      },
    });
  }

  updatePatientSchedule(shift: IPatientShiftEvent) {
    return this.apiService.makeRequest<void>('put', `patients/patient/schedule`, {
      payload: {
        idScheduleEvent: shift.idScheduleEvent,
        startTime: shift.startTime,
        durationInMinutes: shift.durationInMinutes,
        acuityOnPreMinutes: shift.acuityOnPreMinutes,
        acuityOffPreMinutes: shift.acuityOffPreMinutes,
        acuityOnPostMinutes: shift.acuityOnPostMinutes,
        acuityOffPostMinutes: shift.acuityOffPostMinutes,
      },
    });
  }

  getPatients(legacyIdCenter: string | number, scheduleTypeId?: number) {
    let options: IRequestBody = {};
    if (scheduleTypeId) {
      options = {
        queryParams: { scheduleTypeId },
      };
    }
    return this.apiService.makeRequest<IPatientsModalResponse[]>('get', `patients/clinic/${legacyIdCenter}`, options);
  }

  downloadPatientsCSV(legacyIdCenter: string | number) {
    return this.apiService.downloadFile('get', `patients/clinic/${legacyIdCenter}/download`);
  }

  deletePatient(legacyIdClinic: number, IdPatient: number) {
    return this.apiService.makeRequest<IPatientsModalResponse[]>(
      'DELETE',
      `patients/clinic/${legacyIdClinic}/patient/${IdPatient}`,
    );
  }

  getPatient(legacyIdCenter: number, patientId: number) {
    return this.apiService.makeRequest<IPatientInfoModalResponse>(
      'get',
      `patients/clinic/${legacyIdCenter}/patient/${patientId}`,
    );
  }
  getTransportation(legacyIdCenter: number) {
    return this.apiService.makeRequest<ITransportationListResponse[]>(
      'get',
      `patients/clinic/${legacyIdCenter}/transportation`,
    );
  }
  deleteTransportation(transportId: number) {
    return this.apiService.makeRequest<void>('DELETE', `patients/clinic/transportation/${transportId}`);
  }
  addTransportation(legacyIdClinic: number, transportationName: string) {
    return this.apiService.makeRequest<void>('POST', `patients/clinic/${legacyIdClinic}/transportation`, {
      payload: { transportationName },
    });
  }
  getPhysician(legacyIdCenter: number) {
    return this.apiService.makeRequest<IPhysiciansListResponse[]>(
      'get',
      `patients/clinic/${legacyIdCenter}/physicians`,
    );
  }
  deletePhysician(legacyIdClinic: number, idProvider: number) {
    return this.apiService.makeRequest<void>('DELETE', `patients/clinic/${legacyIdClinic}/physicians/${idProvider}`);
  }
  addPhysician(legacyIdClinic: number, firstName: string, lastName: string) {
    return this.apiService.makeRequest<void>('POST', `patients/clinic/${legacyIdClinic}/physicians`, {
      payload: { firstName, lastName },
    });
  }
}
