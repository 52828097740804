import { Injectable } from '@angular/core';
import { OktaAuthStateService } from '@okta/okta-angular';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  public currentUser$: BehaviorSubject<UserLogin> = new BehaviorSubject(undefined as any);
  private loggedinSW = false;

  constructor(
    private apiService: ApiService,
    private cookieService: CookieService,
    private oktaAuthStateService: OktaAuthStateService,
  ) {}
  public async tryLogin(ignoreErrors?: boolean) {
    // Try regular login then Okta login
    if (environment.requireSSO) {
      return (await this.isLoggedIn(ignoreErrors)) || (await this.oktaLogin());
    } else {
      return await this.isLoggedIn(ignoreErrors);
    }
  }

  public async isLoggedIn(ignoreErrors?: boolean) {
    if (this.currentUser$.value && Object.keys(this.currentUser$.value).length > 0) {
      return true;
    } else {
      try {
        const res = await firstValueFrom(
          this.apiService.makeAuthRequest<UserLogin>('post', 'session-validation', {
            loadingMessage: 'Authenticating User',
            ignoreErrors,
          }),
        );
        this.currentUser$.next(res);
        return true;
      } catch (err) {
        console.error('users-verify-err', err);
      }
    }
    return false;
  }

  public async logout(silent = false) {
    this.currentUser$.next(undefined as any);

    try {
      await firstValueFrom(
        this.apiService.makeAuthRequest('get', 'logout', {
          loadingMessage: silent ? undefined : 'You are being logged out',
          showIndicator: !silent,
        }),
      );
    } catch (_err) {
      // if it fails just continue on
    }

    this.cookieService.deleteAll();
    localStorage.clear();
    sessionStorage.clear();
  }

  public getUserLogin() {
    return this.currentUser$.value;
  }

  public async loginSSO(idToken: string) {
    // first login to services, then log in to cold fusion
    const user = await firstValueFrom(
      this.apiService.makeAuthRequest<UserLogin>('post', 'login-sso', {
        loadingMessage: 'Authenticating User',
        payload: { idToken },
        ignoreErrors: false,
      }),
    );
    this.currentUser$.next(user);
  }

  public async oktaLogin() {
    const authState = await firstValueFrom(this.oktaAuthStateService.authState$);
    if (authState.isAuthenticated) {
      try {
        await this.loginSSO(authState.idToken.idToken);
        return true;
      } catch (error) {
        console.log(error);
      }
    }
    return false;
  }

  public async loginNonSSO(username: string, password: string) {
    const user = await firstValueFrom(
      this.apiService.makeAuthRequest<UserLogin>('post', 'login-sw', {
        loadingMessage: 'Authenticating User',
        payload: {
          username,
          password,
        },
        ignoreErrors: true,
      }),
    );
    this.currentUser$.next(user);

    return user;
  }
  // Check if user has ANY of the permissions
  public checkUserPermissionsAny(permissions: string[]): boolean {
    const cu = this.currentUser$.value;
    return permissions.some((permission) => cu?.scope?.includes(permission));
  }
  // Check if user has ALL of the permissions
  public checkUserPermissionsAll(permissions: string[]): boolean {
    const cu = this.currentUser$.value;
    return permissions.every((permission) => cu?.scope?.includes(permission));
  }
  //Check user role code
  public checkUserRole(roles: string[]): boolean {
    const user = this.currentUser$.value;
    return roles.some((role) => role === user.userCenterRoleCode);
  }

  public userIsFloat(): boolean {
    return this.checkUserPermissionsAny(['FLOATELIGIBLE', 'FLOATPERMANENT']);
  }
  public userIsSysAdmin(): boolean {
    return this.checkUserRole(['SA', 'JRSA']);
  }

  public checkEnvironmentAny(names: string[]): boolean {
    const env = environment.env;
    return names.some((name) => env === name);
  }
  public checkEnvironmentAll(names: string[]): boolean {
    const env = environment.env;
    return names.every((name) => env === name);
  }
}

export interface UserLogin {
  uid: string;
  hasClinicsSaved: boolean;
  hashtclinics: boolean;
  userCenter: string;
  userCenterRole: string;
  userCenterRoleCode: string;
  userId: number;
  userLogin: string;
  scope: string[];
  staffId: number;
  swStaffId: number;
  swUri: string;
  jwt: string;
  tempPassword?: boolean;
}
