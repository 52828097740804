<p-dialog
  [(visible)]="displayModal"
  [modal]="true"
  [header]="title"
  class="modal-base ca-container"
  [style]="{ width: '400px' }">
  <ng-template pTemplate="content">
    <h5 class="form-title">{{ podShiftChairDisplay }}</h5>
    <form #caForm="ngForm" (ngSubmit)="submitForm()">
      <div class="body">
        <!-- Chair Status Section -->
        <section id="ca-status">
          <div class="form-group">
            <label for="ca-chair-status" class="form-label">Chair Status</label>
            <select
              id="ca-chair-status"
              class="form-control"
              [(ngModel)]="chairStatus"
              name="chairStatus"
              (change)="onChairStatusChange()"
              required>
              <option *ngFor="let status of chairStatuses" [value]="status.LC">
                {{ status.LookUpValue }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label for="ca-reason-code" class="form-label">
              Reason
              <span *ngIf="isReasonRequired()">*</span>
            </label>
            <select
              id="ca-reason-code"
              class="form-control"
              [(ngModel)]="reasonCode"
              name="reasonCode"
              [disabled]="chairStatus === 'AVAILABLE'"
              [required]="isReasonRequired()">
              <option value="" disabled>Select Reason</option>
              <option *ngFor="let reason of reasonDescriptions" [value]="reason.LC">
                {{ reason.LookUpValue }}
              </option>
            </select>
            <p class="form-error" *ngIf="submitPressed && isReasonRequired() && !reasonCode">
              Reason is required when status is not set to Available.
            </p>
          </div>
        </section>

        <!-- Station Information Section -->
        <section id="ca-station">
          <div class="form-group">
            <label for="ca-station-type" class="form-label">Station Type</label>
            <input
              id="ca-station-type"
              class="form-control align-center"
              type="text"
              [value]="stationType?.display"
              disabled />
          </div>
          <div class="form-group">
            <label for="ca-station-zone" class="form-label">Station Zone</label>
            <input
              id="ca-station-zone"
              class="form-control align-center"
              type="text"
              [value]="stationZone?.display"
              disabled />
          </div>
        </section>

        <!-- Additional Information Section -->
        <section id="ca-extras">
          <div class="form-group">
            <label for="ca-additional-info" class="form-label">Additional Information</label>
            <textarea
              id="ca-additional-info"
              class="form-control"
              maxlength="500"
              rows="5"
              [(ngModel)]="additionalInfo"
              name="additionalInfo"></textarea>
          </div>
        </section>

        <div class="call-to-action">
          <button pButton type="submit" class="btn btn-primary" [disabled]="caForm.invalid || !hasChanges()">
            Submit
          </button>
          <button type="button" class="btn btn-default" (click)="closeModal()">Cancel</button>
        </div>
      </div>
    </form>
  </ng-template>
</p-dialog>
