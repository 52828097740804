import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ClinicConfigTypeId } from 'src/app/shared/enums/clinic-config';
import { FrequencyInterval } from 'src/app/shared/enums/frequency-intervals';
import { ApiService, IRequestBody } from 'src/app/shared/services/api.service';

import {
  IAddScheduleStaffShift,
  ICopyPatientScheduleData,
  ICopyPatientScheduleResponse,
  ILegacyScheduleDetail,
  IPatientScheduleEventRes,
  IPatientScheduleLastUpdatedDetails,
  IPatientTreatmentEvent,
  IStateRatio,
  IUpdateScheduleStaffShift,
} from '../models/schedule-patient.models';

@Injectable({
  providedIn: 'root',
})
export class PatientScheduleService {
  constructor(private api: ApiService) {}

  private $scheduleUpdate = new Subject<void>();

  public watchScheduleUpdates() {
    return this.$scheduleUpdate.asObservable();
  }
  public UpdateSchedule() {
    return this.$scheduleUpdate.next();
  }

  getPatientSchedules(
    clinicId: number,
    idCenter: number,
    clinicConfigIds: number[],
    idEventSchedules: number[],
  ): Observable<IPatientTreatmentEvent[]> {
    let queryParams = new HttpParams().append('clinicId', clinicId).append('idCenter', idCenter);
    if (clinicConfigIds?.length) queryParams = queryParams.append('clinicConfigIds', clinicConfigIds.join(','));
    if (idEventSchedules?.length) queryParams = queryParams.append('idEventSchedules', idEventSchedules.join(','));
    return this.api.makeRequest('get', `patient-schedule/schedules/treatments`, {
      queryParams,
      ignoreErrors: true,
    });
  }
  getStaffShifts(
    clinicId: number,
    idCenter: number,
    clinicConfigIds: number[],
    idEventSchedules: number[],
  ): Observable<IPatientTreatmentEvent[]> {
    let queryParams = new HttpParams().append('clinicId', clinicId).append('idCenter', idCenter);
    if (clinicConfigIds?.length) queryParams = queryParams.append('clinicConfigIds', clinicConfigIds.join(','));
    if (idEventSchedules?.length) queryParams = queryParams.append('idEventSchedules', idEventSchedules.join(','));
    return this.api.makeRequest('get', `patient-schedule/schedules/patient-staff`, {
      queryParams,
      ignoreErrors: true,
    });
  }
  // startDate and endDate should be formatted as 'YYYY-MM-DD'
  // scheduleSourceType should be 5 (master) or 8 (sandbox)
  getWizardHours(clinicId: number, startDate: string, endDate: string, days: string): Observable<any> {
    return this.api.makeRequest(
      'get',
      `optimizer/wizard/hours/${clinicId}?startDate=${startDate}&endDate=${endDate}&days=${days}`,
      {
        ignoreErrors: true,
      },
    );
  }

  startWizard(
    idCenter: number,
    idEventSchedule: number,
    scheduleSourceType: ClinicConfigTypeId,
    frequencyInterval: FrequencyInterval,
    rnRatio: number,
    userId: number,
  ): Observable<any> {
    return this.api.makeRequest('post', `optimizer/wizard/${idCenter}`, {
      payload: {
        eventScheduleID: idEventSchedule,
        sourceSchedule: scheduleSourceType,
        frequencyInterval,
        rnRatio,
        userId,
      },
    });
  }

  cancelWizard(clinicId: number, userId: string): Observable<any> {
    return this.api.makeRequest('post', `optimizer/cancel-wizard/${clinicId}`, {
      payload: {
        userId,
      },
    });
  }

  getRatios(idCenter: number): Observable<IStateRatio[]> {
    return this.api.makeRequest('get', `patient-schedule/ratios/${idCenter}`);
  }

  public addStaffScheduleShift(scheduleStaffShift: IAddScheduleStaffShift): Observable<void> {
    return this.api.makeRequest<void>('post', `patient-schedule/schedules/add-staff-shift`, {
      payload: {
        ...scheduleStaffShift,
      },
    });
  }

  public updateStaffScheduleShift(scheduleStaffShift: IUpdateScheduleStaffShift): Observable<void> {
    return this.api.makeRequest<void>('put', `patient-schedule/schedules/update-staff-shift`, {
      payload: {
        ...scheduleStaffShift,
      },
    });
  }

  public refreshSandboxFromMaster(eventScheduleId: number): Observable<ICopyPatientScheduleResponse> {
    return this.api.makeRequest<ICopyPatientScheduleResponse>(
      'post',
      `patient-schedule/schedules/refresh-sandbox-from-master`,
      {
        payload: {
          eventScheduleId,
        },
      },
    );
  }

  public copySandboxToMaster(eventScheduleId: number): Observable<ICopyPatientScheduleResponse> {
    return this.api.makeRequest<ICopyPatientScheduleResponse>(
      'post',
      `patient-schedule/schedules/copy-sandbox-to-master`,
      {
        payload: {
          eventScheduleId,
        },
      },
    );
  }

  public copyWizardToMasterOrSandbox(copyPatientScheduleData: ICopyPatientScheduleData): Observable<number> {
    return this.api.makeRequest<number>('post', `patient-schedule/schedules/copy-wizard-to-master-or-sandbox`, {
      payload: {
        ...copyPatientScheduleData,
      },
    });
  }

  /**
   * Function to create a new patient treatment event
   * @param idWeekDay - optional. If this is omitted, the treatment will be created for all 3 days of the schedule
   * If this param is included, the treatment will only be created for a specific day (ie. this will create a shared chair)
   */
  public createTreatmentEvent(
    idCenter: number,
    idEventSchedule: number,
    idChair: number,
    scheduleShift: number,
    startTime: string,
    durationInMinutes: number,
    idPatient: number,
    acuityOnPre_Minutes: number,
    acuityOffPre_Minutes: number,
    acuityOnPost_Minutes: number,
    acuityOffPost_Minutes: number,
    idWeekDay?: number,
  ): Observable<any> {
    return this.api.makeRequest<void>('post', `patient-schedule/create-treatment`, {
      payload: {
        idCenter,
        idEventSchedule,
        idChair,
        scheduleShift,
        startTime,
        durationInMinutes,
        idPatient,
        acuityOnPre_Minutes,
        acuityOffPre_Minutes,
        acuityOnPost_Minutes,
        acuityOffPost_Minutes,
        idWeekDay,
      },
    });
  }

  /**
   * Function to close a patient treatment event
   * @param idWeekDay - optional. If this is omitted, the treatment will be closed for all 3 days of the schedule
   * If this param is included, the treatment will only be closed for a specific day (ie. this will create a shared chair)
   */
  public closeTreatmentEvent(
    idEventSchedule: number,
    idChair: number,
    scheduleShift: number,
    idWeekDay?: number,
    startTime?: string,
    duration?: number,
  ): Observable<any> {
    return this.api.makeRequest<void>('post', `patient-schedule/close-treatment`, {
      payload: {
        idEventSchedule,
        idChair,
        scheduleShift,
        idWeekDay,
        startTime,
        duration,
      },
    });
  }

  public createOpenChair(
    idEventSchedule: number,
    idChair: number,
    scheduleShift: number,
    startTime: string,
    duration?: number,
    idWeekDay?: number,
  ): Observable<any> {
    return this.api.makeRequest<void>('post', `patient-schedule/open-chair`, {
      payload: {
        idEventSchedule,
        idChair,
        scheduleShift,
        startTime,
        duration,
        idWeekDay,
      },
    });
  }

  getScheduleForPatient(idPatient: number, scheduleTypeId: number): Observable<IPatientScheduleEventRes[]> {
    let options: IRequestBody = {};
    if (scheduleTypeId) {
      options = {
        queryParams: { scheduleTypeId },
      };
    }
    return this.api.makeRequest('get', `patient-schedule/patient-treatments/${idPatient}`, options);
  }

  closeTreatmentsForPatient(idPatient: number): Observable<any> {
    return this.api.makeRequest<any>('post', `patient-schedule/patient-treatments/close/${idPatient}`);
  }

  getScheduleDetails(idCenter: number, idEventSchedule: number): Observable<ILegacyScheduleDetail> {
    return this.api.makeRequest('get', `patient-schedule/details/${idCenter}/${idEventSchedule}`);
  }

  public swapPatients(
    idScheduleEvent_A: number,
    idScheduleEvent_B: number,
    targetShiftNumber: number,
    targetChairId: number,
  ): Observable<void> {
    return this.api.makeRequest<void>('put', `patients/swap`, {
      payload: {
        idScheduleEvent_A,
        idScheduleEvent_B,
        targetShiftNumber,
        targetChairId,
      },
    });
  }
  public insertPatient(
    idPatientMaster: number,
    idCenter: number,
    idEventSchedule: number,
    patientDiseaseStatus_Id: string,
    physician_IdProvider: number,
    idTransportation: number,
    idChair: number,
    scheduleShift: number,
    startDate: string,
    startTime: string,
    scheduleConstraintNote: string,
    patientNote: string,
    CSSDisplay: string,
    durationInMinutes: number,
    acuityOnPre_Minutes: number,
    acuityOffPre_Minutes: number,
    acuityOnPost_Minutes: number,
    acuityOffPost_Minutes: number,
  ): Observable<any> {
    return this.api.makeRequest<void>('post', `patient-schedule/insert-patient`, {
      payload: {
        idPatientMaster,
        idCenter,
        idEventSchedule,
        patientDiseaseStatus_Id,
        physician_IdProvider,
        idTransportation,
        idChair,
        scheduleShift,
        startDate,
        startTime,
        scheduleConstraintNote,
        patientNote,
        CSSDisplay,
        durationInMinutes,
        acuityOnPre_Minutes,
        acuityOffPre_Minutes,
        acuityOnPost_Minutes,
        acuityOffPost_Minutes,
      },
    });
  }

  public getLastUpdatedDetails(centerId: number): Observable<IPatientScheduleLastUpdatedDetails> {
    return this.api.makeRequest<IPatientScheduleLastUpdatedDetails>('post', `patient-schedule/last-updated-details`, {
      payload: {
        centerId,
      },
    });
  }
}
