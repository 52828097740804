<div>
  <div class="patient-list">
    <p-table
      styleClass="p-datatable-striped"
      [scrollable]="true"
      scrollHeight="22rem"
      [value]="patients"
      #existingPatientsTable
      [filterDelay]="0"
      [globalFilterFields]="filterFields">
      <ng-template pTemplate="header">
        <tr class="search-field">
          <td colspan="5">
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input
                pInputText
                type="text"
                (input)="onFilterInput($event, existingPatientsTable)"
                placeholder="Search patients" />
            </span>
          </td>
        </tr>
        <tr>
          <th pSortableColumn="PatientLastName">
            Name
            <p-sortIcon field="PatientLastName" />
          </th>
          <th pSortableColumn="MedicalRecordNumber">
            MRN
            <p-sortIcon field="MedicalRecordNumber" />
          </th>
          <th pSortableColumn="WeekDays">
            Days
            <p-sortIcon field="WeekDays" />
          </th>
          <th pSortableColumn="DurationInMinutes">
            Duration
            <p-sortIcon field="DurationInMinutes" />
          </th>
          <th>Add Patient</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-patient>
        <tr [ngClass]="['patient-row', patient.HepBStatus]">
          <td>{{ patient.PatientFirstName }} {{ patient.PatientLastName }}</td>
          <td>{{ patient.MedicalRecordNumber }}</td>
          <td>{{ patient.WeekDays }}</td>
          <td>{{ patient.DurationInMinutes | minutesToTime }}</td>
          <td class="button-col"><p-button icon="pi pi-plus" (onClick)="addPatient(patient)" /></td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
