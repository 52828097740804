// DONT CHANGE POSITION OF ANYTHING IN THIS LIST IT IS ORDERED BY DISPLAY PRIORITY
export const orderedNeedsPriority = [
  'wheelchair',
  'hoyerlift',
  'catheter',
  'avfistula',
  'avgraft',
  'assist',
  'transient',
  'newpatient',
  'anchor',
  'integrated',
];
