import { Component, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DayLettersUnique, DayNamesFull, SingleDayFrequencyInterval } from 'src/app/shared/enums/frequency-intervals';

import { IPatientsModalResponse } from '../../models/patient-modal';
import {
  createEmptyTreatment,
  DEFAULT_ACCUITIES,
  IPatientTreatmentEvent,
  putPatientInTreatment,
} from '../../models/schedule-patient.models';
import { PatientScheduleService } from '../../services/patient-schedule.service';
import { CreateOpenChairModalComponent } from '../create-open-chair-modal/create-open-chair-modal.component';
import { PatientsModalService } from '../patients-modal/patients-modal.service';

@Component({
  standalone: false,
  selector: 'app-shared-chair-edit-modal',
  templateUrl: './shared-chair-edit-modal.component.html',
  styleUrls: ['./shared-chair-edit-modal.component.scss'],
})
export class SharedChairEditModalComponent implements OnInit {
  public treatments: IPatientTreatmentEvent[][];
  public patients: IPatientsModalResponse[];
  public legacyIdCenter: number;
  public podName: string;
  public chairName: string;
  public shiftNumber: number;
  public dayNamesFull = DayNamesFull;
  public isValidPatientToDrag: boolean = false;
  public scheduleType: number;
  public filterFields = ['PatientFirstName', 'PatientLastName'];
  private draggedPatient: IPatientsModalResponse;
  private dirty = false;

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private patientsModalService: PatientsModalService,
    private patientScheduleService: PatientScheduleService,
    private dialogService: DialogService,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.treatments = this.config.data.treatments;
    this.legacyIdCenter = this.config.data.legacyIdCenter;
    this.podName = this.config.data.podName;
    this.chairName = this.config.data.chairName;
    this.shiftNumber = this.config.data.shiftNumber;
    this.scheduleType = this.config.data.scheduleType;
    this.getPatients();
  }

  private getPatients() {
    this.patientsModalService.getPatients(this.legacyIdCenter, this.scheduleType).subscribe((res) => {
      this.patients = res;
    });
  }

  public removeTreatment(tx: IPatientTreatmentEvent[]) {
    if (tx.length) {
      let treatment = tx[0];
      if (treatment.LastName && treatment.SharedChairDay) {
        this.patientScheduleService
          .closeTreatmentEvent(
            treatment.IdEventSchedule,
            treatment.ChairId,
            treatment.ShiftNumber,
            treatment.SharedChairDay - 1,
          )
          .subscribe(() => {
            this.dirty = true;
            tx.pop();
            tx.push(
              createEmptyTreatment(
                treatment.PodId,
                treatment.ChairId,
                treatment.ShiftNumber,
                treatment.SharedChairDay,
                treatment.IdEventSchedule,
                treatment.ClinicConfigId,
              ),
            );
            this.treatments = cloneDeep(this.treatments);
            this.getPatients();
          });
      }
    }
  }

  public createTreatment(tx: IPatientTreatmentEvent[], patient: IPatientsModalResponse) {
    if (tx.length) {
      this.patientScheduleService
        .createTreatmentEvent(
          this.legacyIdCenter,
          tx[0].IdEventSchedule,
          tx[0].ChairId,
          tx[0].ShiftNumber,
          tx[0].StartTime || '00:00',
          patient.DurationInMinutes,
          patient.IdPatient,
          DEFAULT_ACCUITIES.AcuityOnPre,
          DEFAULT_ACCUITIES.AcuityOffPre,
          DEFAULT_ACCUITIES.AcuityOnPost,
          DEFAULT_ACCUITIES.AcuityOffPost,
          tx[0].SharedChairDay - 1,
        )
        .subscribe((res) => {
          if (res?.[0].SuccessCode !== 1) {
            this.messageService.add({
              severity: 'error',
              summary: 'Create Treatment Error',
              detail: `Could not create the treatment: ${res[0].ErrMsg}`,
            });
            return;
          }
          this.dirty = true;
          const newTx = putPatientInTreatment(tx[0], patient);
          newTx.ScheduleEventId = res[0].IdScheduleEvent;
          newTx.AcuityOnPre = DEFAULT_ACCUITIES.AcuityOnPre;
          newTx.AcuityOffPre = DEFAULT_ACCUITIES.AcuityOffPre;
          newTx.AcuityOnPost = DEFAULT_ACCUITIES.AcuityOnPost;
          newTx.AcuityOffPost = DEFAULT_ACCUITIES.AcuityOffPost;
          newTx.FrequencyInterval = SingleDayFrequencyInterval[tx[0].SharedChairDay - 1];
          tx.pop();
          tx.push(newTx);
          this.treatments = cloneDeep(this.treatments);
          this.draggedPatient = null;
          this.getPatients();
        });
    }
  }

  public checkValidPatientForSharedChair(tx: IPatientTreatmentEvent[]): void {
    // Check if the patient already has a treatment on this day
    const ptDays = this.draggedPatient?.WeekDays ? this.draggedPatient?.WeekDays.split(',') : [];
    this.isValidPatientToDrag = ptDays.indexOf(DayLettersUnique[tx[0].SharedChairDay - 1]) < 0;
  }

  public drop(tx: IPatientTreatmentEvent[]) {
    if (tx.length && !tx[0].LastName && this.isValidPatientToDrag) {
      this.createTreatment(tx, this.draggedPatient);
    } else {
      this.draggedPatient = null;
    }
  }
  public dragStart(patient): void {
    this.draggedPatient = patient;
  }
  public close() {
    if (
      this.treatments.every(
        (t) =>
          !t.length || (t[0].ChairStatus.toLowerCase().indexOf('unavail') < 0 && !t[0].LastName && !t[0].StartTime),
      )
    ) {
      // All treatments have been removed, so convert to Open Chair
      this.dialogService
        .open(CreateOpenChairModalComponent, {
          header: 'Enter Start Time for Open Chair',
          width: '400px',
          closable: true,
        })
        .onClose.subscribe((result) => {
          if (result?.startTime) {
            this.createOpenChair(result.startTime);
          }
        });
      return;
    } else {
      this.ref.close(this.dirty);
    }
  }

  public editTreatmentTime() {
    this.dirty = true;
  }
  private createOpenChair(startTime: string): void {
    this.patientScheduleService
      .createOpenChair(
        this.treatments[0][0].IdEventSchedule,
        this.treatments[0][0].ChairId,
        this.treatments[0][0].ShiftNumber,
        startTime,
        240,
      )
      .subscribe({
        next: () => {
          this.messageService.add({ severity: 'success', detail: 'Open chair created successfully.' });
          this.ref.close(this.dirty);
        },
        error: (err) => {
          this.messageService.add({ severity: 'error', detail: err.errorMsg });
        },
      });
  }
  onFilterInput(event: Event, table: any) {
    const input = (event.target as HTMLInputElement).value;
    table.filterGlobal(input, 'contains');
  }
}
